import React from 'react';
import {Box, Typography, Button} from '@mui/material';
import aboutImage from '../assets/images/logo.png'; // Adjust the path as necessary

const About: React.FC = () => {
    return (
        <Box id="about" className="about-section">
            <Box className="about-content">
                <Box className="about-image">
                    <img src={aboutImage} alt="FlexBot Security" style={{maxWidth: '100%'}}/>
                </Box>
                <Box className="about-text">
                    <Typography variant="h2" gutterBottom>
                        About FlexBot Security
                    </Typography>
                    <Typography variant="body1" sx={{margin: '20px 0'}}>
                        FlexBot Security is the ultimate solution for maintaining a safe and secure environment on your
                        Discord server.
                        With cutting-edge security features, comprehensive moderation tools, and seamless user
                        verification processes,
                        FlexBot Security ensures your community is protected from unauthorized access, threats, and
                        disruptive behavior.
                        FlexBot Security is designed to be easy to use, highly effective, and fully customizable to fit
                        the unique needs of your server.
                    </Typography>
                    <Button variant="contained" color="secondary"
                            href="https://discord.com/oauth2/authorize?client_id=1246504417469923369&scope=bot&permissions=18978118921399&application.commands">
                        Add FlexBot Security to Your Server
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default About;
