import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import quote from '../assets/images/quote.svg';
import star from '../assets/images/star.svg';
import testimonial1 from '../assets/images/t-1.jpg';
import testimonial2 from '../assets/images/t-2.jpg';
import testimonial3 from '../assets/images/t-3.jpg';

const testimonials = [
    {
        name: 'Alex',
        role: 'Community Manager / Tech Haven',
        text: 'FlexBot has revolutionized the way we manage our Discord server. The security and moderation tools are top-notch and incredibly easy to use.',
        // image: testimonial1
    },
    {
        name: 'David',
        role: 'Owner / Creative Corner',
        text: 'Thanks to FlexBot, our server\'s security is rock solid. The verification process ensures that only trusted members gain access.',
        // image: testimonial2
    },
    {
        name: 'Michael',
        role: 'Moderator / Gaming Hub',
        text: 'The automated moderation features of FlexBot have made managing our server so much easier. Highly recommended!',
        // image: testimonial3
    }
];
const Testimonials: React.FC = () => {
    return (
        <Box id="testimonials" sx={{padding: '50px 0', textAlign: 'center', backgroundColor: '#080E1F', color: '#fff'}}>
            <Typography variant="h2" gutterBottom>
                Testimonials
            </Typography>
            <Grid container rowSpacing={{xs: 2, sm: 3, md: 4}} columnSpacing={3}>
                {testimonials.map((testimonial, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Box sx={{padding: 3}}>
                            {/*<img src={quote} alt="quote" style={{maxWidth: '30px', margin: 'auto'}}/>*/}
                            <Typography variant="body1" sx={{marginTop: 2}}>{testimonial.text}</Typography>
                            {/*<img src={testimonial.image} alt={testimonial.name}*/}
                            {/*     style={{maxWidth: '100px', marginTop: '20px', borderRadius: '50%'}}/>*/}
                            <Typography variant="h5" sx={{marginTop: 2}}>{testimonial.name}</Typography>
                            <Typography variant="body2">{testimonial.role}</Typography>
                            <img src={star} alt="rating" style={{maxWidth: '100px', marginTop: '5px'}}/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Testimonials;
