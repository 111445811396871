import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

// Type definitions
interface CommandCategory {
    category: string;
    description: string;
}

// JSON data
const commandsData: CommandCategory[] = [
    {
        category: 'AutoPurge Commands',
        description: 'These commands allow administrators to automatically delete messages in specified channels at regular intervals. The purpose is to keep channels clean and free of clutter by periodically removing older messages, which is particularly useful in high-traffic channels or in servers where information needs to be regularly refreshed.'
    },
    {
        category: 'Anti-Nuke Commands',
        description: 'These commands are designed to protect the server from destructive actions that could be performed by malicious users or bots. The anti-nuke settings allow administrators to monitor and limit actions such as mass banning, kicking, or role and channel modifications. By setting these controls, the server can automatically respond to potential threats and mitigate damage before it escalates.'
    },
    {
        category: 'Anti-Raiding Commands',
        description: 'Anti-raiding commands help protect the server from raids, where a large number of users join the server in a short time with the intent to disrupt or spam. These settings allow administrators to set limits on how many users can join within a specific timeframe, automatically triggering protective measures such as temporary bans or notifications to designated roles. This ensures that the server remains secure and operational during such attacks.'
    },
    {
        category: 'Lockdown Commands',
        description: 'The lockdown commands are used in emergency situations to restrict access to the server or specific parts of it. By enabling lockdown, administrators can prevent members from sending messages or accessing channels, effectively freezing activity until the situation is resolved. This is particularly useful during raids, security breaches, or other critical events where control needs to be quickly re-established.'
    },
    {
        category: 'Server Backup Commands',
        description: 'These commands provide administrators with the ability to back up and restore the server\'s configuration, including roles, channels, and settings. This feature is crucial for disaster recovery, ensuring that if anything goes wrong, the server can be quickly restored to a previous state. Additionally, it allows administrators to preview configurations before applying them, offering a safeguard against accidental changes.'
    },
    {
        category: 'Purge Command',
        description: 'The purge command allows for the bulk deletion of messages in a channel, which is particularly useful for moderating and cleaning up after incidents of spam or inappropriate content. Administrators can specify the number of messages to delete, making it a versatile tool for maintaining the server’s chat environment.'
    },
    {
        category: 'Mentionable Command',
        description: 'This command lets administrators control whether a role can be mentioned by users in the server. By toggling the mentionability of a role, administrators can prevent or allow mass notifications, which is useful for managing attention in large servers and avoiding unnecessary pings.'
    },
    {
        category: 'Setup Command',
        description: 'The setup command is used for the initial configuration of the bot within the server. It allows administrators to specify key settings, such as the logging channel where the bot will send important information and alerts. This ensures that the bot is properly integrated into the server’s moderation and management framework.'
    },
    {
        category: 'Logging Command',
        description: 'The logging command allows administrators to set or change the channel where logs and reports from the bot will be sent. This is important for tracking actions taken by the bot, such as bans, kicks, and other moderation activities, providing transparency and accountability within the server’s management.'
    },
    {
        category: 'Slow Mode Commands',
        description: 'Slow mode commands are used to control the flow of messages in a channel, either by slowing down the frequency at which users can post or by applying these restrictions to specific users. This is useful in managing discussions, preventing spam, and ensuring that conversations remain orderly, especially in active channels where message volume can quickly become overwhelming.'
    },
    {
        category: 'Verification Commands',
        description: 'Verification commands are essential for managing how new members are onboarded into the server. They allow administrators to set up a verification process that can include sending welcome messages, assigning verified roles, and checking for potential security risks like proxy usage or alt accounts. This ensures that only legitimate users gain access to the server, enhancing overall security.'
    },
    {
        category: 'Anti-Link Commands',
        description: 'These commands are used to manage and enforce anti-link policies within the server. Administrators can enable or disable link posting, allow specific links in certain channels, and remove permissions for links that were previously allowed. This helps prevent spam and phishing attempts, keeping the server safe from potentially harmful content.'
    },
    {
        category: 'Utility Commands',
        description: 'Utility commands provide general-purpose functions that enhance the user experience and server management. These include simple commands like checking the bot’s responsiveness, accessing help information, or generating invite links. These tools are essential for everyday interaction with the bot and provide users with quick access to important features.'
    }
];

// Component
const Commands: React.FC = () => {
    return (
        <Box id="commands" sx={{ backgroundColor: '#080E1F', padding: '50px 0', textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
                Commands Overview
            </Typography>
            {commandsData.map((category, categoryIndex) => (
                <Box key={categoryIndex} sx={{ textAlign: 'left', maxWidth: '800px', margin: 'auto', mb: 4 }}>
                    <Card sx={{ mb: 2, backgroundColor: '#383B3F' }}>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>{category.category}</Typography>
                            <Typography variant="body1">{category.description}</Typography>
                        </CardContent>
                    </Card>
                </Box>
            ))}
        </Box>
    );
};

export default Commands;
