import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Banner: React.FC = () => {
  return (
    <Box id="home" className="banner">
      <Box className="banner-content">
        <Typography variant="h1" gutterBottom>
          Elevate Your Discord Server Security with FlexBot Security
        </Typography>
        <Typography variant="h5" gutterBottom>
          Advanced security and moderation tools to keep your community safe.
        </Typography>
        <Button variant="contained" color="primary" href="https://discord.com/oauth2/authorize?client_id=1246504417469923369&scope=bot&permissions=18978118921399&application.commands">
          Add FlexBot Security to Your Server
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
