import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../css/TetrisAnimation.scss';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

interface ApiResponse {
  status: 'SUCCESS' | 'ERROR';
  error?: 'IP_ALREADY_SET' | 'KEY_EXPIRED' | 'ALREADY_VERIFIED' | 'KEY_NOT_FOUND' | 'PROXY_VPN_DETECTED' | 'ALT_DETECTED';
  data?: string;
}

const errorMessages: Record<string, string> = {
  IP_ALREADY_SET: "IP Already Set",
  KEY_EXPIRED: "Key Expired",
  ALREADY_VERIFIED: "Already Verified",
  KEY_NOT_FOUND: "Key Not Found",
  PROXY_VPN_DETECTED: "Proxy/VPN Detected",
  ALT_DETECTED: "Alternative Detected"
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const VerificationPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fadeOut, setFadeOut] = useState(false);

  const query = useQuery();
  const verificationKey = query.get('v');

  useEffect(() => {
    if (!verificationKey) {
      setError('VerificationPage key is missing');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.post<ApiResponse>(
          'https://flexbot.gg/flexbot/api/v1/verify',
          { verificationKey },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        // Simulate a minimum loading time of 3 seconds
        await new Promise(resolve => setTimeout(resolve, 6100));

        setData(response.data);
      } catch (error: any) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
        setTimeout(() => setFadeOut(true), 6100); // Start fade out after a delay
      }
    };

    fetchData();
  }, [verificationKey]);

  if (loading) {
    return (
      <Box className={`tetris-overlay ${fadeOut ? 'tetris-hidden' : ''}`}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h1" color={"White"}>
              Verifying<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span>
            </Typography>
          </Grid>
          <Grid item>
            <Box className="tetris">
              {[...Array(10)].map((_, index) => (
                <div key={index} className={`tetris__block tetris__block--${index + 1}`}>
                  <div className="tetris__block-cell"></div>
                  <div className="tetris__block-cell"></div>
                  <div className="tetris__block-cell"></div>
                  <div className="tetris__block-cell"></div>
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="main-content">
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Alert severity="error">
              <Typography variant="h2">Error: {error}</Typography>
            </Alert>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className="main-content">
      <Grid container justifyContent="center">
        <Grid item xs={10} md={8}>
          <Box className="api-response">
            {data?.status === 'ERROR' && (
              <Alert severity="error">
                <Typography variant="h2">Error: {data.error ? errorMessages[data.error] : 'Unknown Error'}</Typography>
              </Alert>
            )}
            {data?.status === 'SUCCESS' && (
              <Alert severity="success">
                <Typography variant="h2">Success, return back to Discord.</Typography>
                {/*<Typography>{data.data}</Typography>*/}
              </Alert>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VerificationPage;
