import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Link, Container, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/images/logo.png'; 
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

const Logo = styled('img')`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = (
    <>
      <Link href="/" color="inherit" sx={{ margin: 2 }}>Home</Link>
      <Link href="#about" color="inherit" sx={{ margin: 2 }}>About</Link>
      <Link href="#features" color="inherit" sx={{ margin: 2 }}>Features</Link>
      <Link href="#commands" color="inherit" sx={{ margin: 2 }}>Commands Overview</Link>
      <Link href="#howitworks" color="inherit" sx={{ margin: 2 }}>How It Works</Link>
      <Link href="#testimonials" color="inherit" sx={{ margin: 2 }}>Testimonials</Link>
    </>
  );

  const drawerItems = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
      <List>
        <ListItem button component="a" href="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component="a" href="#about">
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component="a" href="#features">
          <ListItemText primary="Features" />
        </ListItem>
        <ListItem button component="a" href="#commands">
          <ListItemText primary="Commands Overview" />
        </ListItem>
        <ListItem button component="a" href="#howitworks">
          <ListItemText primary="How It Works" />
        </ListItem>
        <ListItem button component="a" href="#testimonials">
          <ListItemText primary="Testimonials" />
        </ListItem>
      </List>
    </Box>
  );

  const homeLink = (
    <Link href="/" color="inherit" sx={{ margin: 2 }}>Home</Link>
  );

  const shouldShowHomeLinkOnly = location.pathname === '/verify';

  return (
    <AppBar position="relative">
      <Toolbar>
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Logo src={logo} alt="FlexBot Security" />
          </Box>
          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                {drawerItems}
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexGrow: shouldShowHomeLinkOnly ? 1 : 0 }}>
              {shouldShowHomeLinkOnly ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  {homeLink}
                </Box>
              ) : (
                menuItems
              )}
            </Box>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
