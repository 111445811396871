import React from 'react';
import {Box, Typography, Grid, Card, CardContent, CardMedia} from '@mui/material';
import feature1 from '../assets/images/f-1.svg';
import feature2 from '../assets/images/f-2.svg';
import feature3 from '../assets/images/f-3.svg';
import feature4 from '../assets/images/f-4.svg';

const features = [
    {id: 1, image: feature2, title: 'User VerificationPage', description: 'Ensure every member is verified.'},
    {
        id: 2,
        image: feature1,
        title: 'Advanced Security',
        description: 'Protect your server from unauthorized access and threats.'
    },
    {id: 3, image: feature3, title: 'Automated Moderation', description: 'Automate bans, kicks, mutes, and more.'},
    {
        id: 4,
        image: feature4,
        title: 'Detailed Logging',
        description: 'Keep track of all activities with comprehensive logs.'
    }
];

const Features: React.FC = () => {
    return (
        <Box id="features" sx={{backgroundColor: '#0C1626', color: '#fff', padding: '6rem 0'}}>
            <Typography variant="h3" gutterBottom align="center"
                        sx={{fontSize: '44px', fontWeight: 600, marginBottom: '2rem'}}>
                Features
            </Typography>
            <Grid container spacing={1} sx={{
                display: 'grid',
                gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)'},
                gap: '5px',
                width: '80%', margin: '0 auto',
            }}>
                {features.map(feature => (
                    <Grid item key={feature.id}
                          sx={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%'}}>
                        <Card sx={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '280px',
                            padding: '10px 10px 0px 0px',
                            border: '1px solid #d3d3d3',
                            borderRadius: '6px',
                            transition: '.3s all linear',
                            '&:hover': {transform: 'translateY(-3px)'},
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            textAlign: 'center'
                        }}>
                            <CardMedia
                                component="img"
                                image={feature.image}
                                alt={feature.title}
                                sx={{width: '70px', margin: '0 auto'}}
                            />
                            <CardContent>
                                <Typography variant="h5" sx={{
                                    fontSize: '23px',
                                    fontWeight: 600,
                                    marginBottom: '.5rem'
                                }}>{feature.title}</Typography>
                                <Typography variant="body1" sx={{
                                    fontSize: '16px',
                                    lineHeight: '16px'
                                }}>{feature.description}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Features;
