import { createTheme } from '@mui/material/styles';
import { css } from '@emotion/react';
import bgImage from './assets/images/bg.jpg'; 

const theme = createTheme({
  palette: {
    primary: {
      main: '#0d6efd',
    },
    secondary: {
      main: '#6c757d',
    },
    success: {
      main: '#198754',
    },
    info: {
      main: '#0dcaf0',
    },
    warning: {
      main: '#ffc107',
    },
    background: {
      default: '#0C1626',
      paper: '#080E1F',
    },
    text: {
      primary: '#fff',
      secondary: '#5072df',
    },
  },
  typography: {
    fontFamily: [
      'Golos Text',
      'Space Grotesk',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '70px',
      fontWeight: 600,
      color: '#fff',
    },
    h2: {
      fontSize: '44px',
      fontWeight: 600,
      color: '#fff',
    },
    h3: {
      fontSize: '23px',
      fontWeight: 600,
      color: '#fff',
    },
    h4: {
      fontSize: '23px',
      fontWeight: 600,
      color: '#080E1F',
    },
    h5: {
      fontSize: '18px',
      fontWeight: 500,
      color: 'rgba(255, 255, 255, 1)',
    },
    h6: {
      fontSize: '15px',
      fontWeight: 700,
      color: '#5072df'
    },
    body1: {
      fontSize: '16px',
      lineHeight: '28px',
      color: '#fff',
    },
    body2: {
      fontSize: '16px',
      lineHeight: '28px',
      color: '#fff',
    },
    button: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          border: '1px solid #0d6efd',
          padding: '12px 30px',
          background: 'linear-gradient(90deg, #EE704A 0%, #F53A8B 29.68702003481348%, #7B40E4 61.14201849892488%, #3D9BFC 99.15%)',
          transition: '.3s all linear',
          textTransform: 'capitalize',
          '&:hover': {
            background: 'linear-gradient(90deg, #3D9BFC 0%, #7B40E4 29.68702003481348%, #F53A8B 61.14201849892488%, #EE704A 99.15%)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          paddingTop: '10px',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          justifyContent: 'center',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#080E1F',
          color: '#fff',
          border: '1px solid #0C1626',
          borderRadius: '6px',
          boxShadow: '0 0 20px #0C1626',
          margin: '10px 0',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Golos Text, sans-serif",
          color: '#fff', // --bs-white
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
          position: 'relative',
          display: 'inline-block',
          transition: '.4s all linear',
          textDecoration: 'none', // No underline initially
          '&:hover': {
            color: '#94F3FC', 
          },
          '&:before': {
            content: '""',
            width: 0,
            height: '2px',
            backgroundColor: '#94F3FC', 
            position: 'absolute',
            left: 0,
            bottom: 0,
            transition: '.4s all linear',
          },
          '&:hover:before': {
            width: '100%',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          alignItems: 'stretch',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.feature-card-title': {
            marginTop: '16px',
            color: '#fff',
          },
          '&.feature-card-description': {
            marginTop: '8px',
            color: '#fff',
          },
        },
      },
    },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '3rem',
            color: '#fff'
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardError: {
            backgroundColor: '#dc3545', // Red for error
            color: '#fff', // White text
          },
          standardSuccess: {
            backgroundColor: '#0d6efd', // Dark blue for success
            color: '#fff', // White text
          },
        },
      },
    },
  });

const globalStyles = css`



  .about-section {
    background-color: #080E1F;
    padding: 50px 0;
    text-align: left;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
    text-align: left;
  }

  .about-image {
    max-width: 400px;
    margin-right: 50px;
  }

  .about-text {
    flex: 1;
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .header-banner-wrapper {
    min-height: 100vh;
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
    width: 100%;
  }

  .banner {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .header-banner-wrapper::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(12, 22, 38, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }


  .header-content {
    z-index: 1;
    text-align: center;
    width: 100%;
  }

  #root {
    width: 100%;
  }
.api-response {
  width:100%;
}

.banner-content{
  z-index: 1;
  text-align: center;
  width: 70%;
}

  .banner-content h5,
  .header-content h5 {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    padding: 0 0 30px 0;
  }

  .banner-content a,
  .header-content a {
    display: inline-block;
    padding: 12px 30px;
    background: linear-gradient(90deg, #EE704A 0%, #F53A8B 29.68702003481348%, #7B40E4 61.14201849892488%, #3D9BFC 99.15%);
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    border-radius: 30px;
    transition: .3s all linear;
  }

  .banner-content a:hover,
  .header-content a:hover {
    background: linear-gradient(90deg, #3D9BFC 0%, #7B40E4 29.68702003481348%, #F53A8B 61.14201849892488%, #EE704A 99.15%);
  }
  
.works{
    background-color: #0C1626;
    color: #fff;
}
.works-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.single-works{
    width: 380px;
    position: relative;
}
.single-works img{
    width: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    
}
.single-works:nth-child(1) img{
    right: -65px;
}
.single-works:nth-child(2) img{
    right: -25px;
}
.single-works:nth-child(4) img{
    right: -55px;
}
.single-works:nth-child(3) img{
    display: none;
}



`;

export { theme, globalStyles };
