import React from 'react';
import {Box} from '@mui/material';
import Header from "../components/Header";
import Banner from "../components/Banner";
import About from "../components/About";
import Features from "../components/Features";
import Commands from "../components/Commands";
import HowItWorks from "../components/HowItWorks";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer"; // Adjust the path as necessary

const HomePage: React.FC = () => {
    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            <div className="header-banner-wrapper">
                <Header />
                <Banner />
            </div>
            <About />
            <Features />
            <Commands />
            {/*<HowItWorks />*/}
            <Testimonials />
            <Footer />
        </Box>
    );
};

export default HomePage;