import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ textAlign: 'center', py: 3, backgroundColor: '#0C1626' }}>
      <Typography variant="body2">© 2024 Begin studio LLC. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;