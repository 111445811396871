import React from 'react';
import {Box} from '@mui/material';
import Header from './components/Header';
import VerificationPage from './page/VerificationPage';
import './css/TetrisAnimation.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./page/HomePage";


const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route
                    path="/verify"
                    element={
                        <Box sx={{width: '100%', overflowX: 'hidden'}}>
                            <div className="header-banner-wrapper">
                                <Header/>
                                <VerificationPage/>
                            </div>
                        </Box>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
